<template>
  <FocusTrap>
    <div class="card">

    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h4 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp;&nbsp;Cone List</h4>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="mytable"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="_id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="name" data-sortable="true">Name</th>
          <!--<th data-field="group.name" data-sortable="true">Group</th>-->
          <!--<th data-field="category.name" data-sortable="true">Category</th>-->
          <!--<th data-field="brand.name" data-sortable="true">Brand</th>-->
          <th data-field="unit.name" data-sortable="true">Unit</th>
          <!--<th data-field="group.gstrate" data-sortable="true">GST Rate</th>-->
          <!--<th data-field="group.itcrate" data-sortable="true">ITC</th>-->
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <vmodal name="item_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="70%" height="50%" @click="showModal" >
      <ConeForm v-bind:myitem="item"></ConeForm>
    </vmodal>
    <!-- End of Modal Window -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

  </div>
  </FocusTrap>
</template>

<script>
  import ConeForm from '@/views/fms/cone/ConeForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ConeView',
    components: {
      ConeForm,
      FabButton,
      moment
    },
    data () {
      return {
        readonly: false,
        item: JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0}'),
        mytable: {},
        units: []
      }
    },
    created () {

    },
    mounted () {
      let self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }

        }
      });

      $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#item_modal_window').on('shown.bs.modal', function () {
        $('#txtname').trigger('focus');
      });

      $('#item_modal_window').on('hide.bs.modal', function () {
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#item_modal_window').modal('hide');
      });

      self.loadData();

    },
    methods: {
      closeModal() {
        this.$modal.hide('item_modal_window')
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.item= JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}');
        this.$modal.show('item_modal_window');
      },
      clear(){
        this.item= JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}');
      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        self.$data.item = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/item/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.item = data.data;
            self.$modal.show('item_modal_window');
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.item.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/item/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('item_modal_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/item/ldgp/904/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
