<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Cone Information</h5>
      <div class="header-elements">
        <div class="list-icons">

          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Item Name here..." maxlength="100" v-if="item" v-model="item.name" autofocus required>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="item" v-model="item.p_name">
          </div>
        </div>



        <div class="form-group row">
          <label class="col-md-2 col-form-label">Unit of measure:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="item" v-model="item.unit.code" disabled>
              <option value="1">Nos</option>
              <option value="2">Kgs</option>
              <option value="3">Mts</option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Min Qty:</label>
          <div class="col-md-2">
            <input type="number" class="form-control text-right" placeholder="min stock qty" min="0" v-if="item" v-model="item.min_qty">
          </div>

          <label class="col-md-1 col-form-label">Max Qty:</label>
          <div class="col-md-2">
            <input type="number" class="form-control text-right" placeholder="max stock qty" min="0" v-if="item" v-model="item.max_qty">
          </div>

        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ConeForm',
    data () {
      return {
        item: {},
        groups: [],
        categories: [],
        brands:[]
      }
    },
    props: {
      myitem: {
        type: Object,
        required: false,
        default: JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}')
      }
    },
    beforeMount () {
      this.item = this.myitem; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.item = JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()


      self.loadBrands();
      self.loadCategories();
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeModal();
      },
      clear(){
        this.$data.item = JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}')
      },
      loadAll(){
        loadBrands();
        loadCategories();
      },
      loadBrands(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/brand/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.brands = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      loadCategories(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/categories`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.categories = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      saveDocument(){
        let self = this;

        self.$data.item.ledger_group.code = 904;
        self.$data.item.group.code = 904;
        self.$data.item.design.code = 1;
        self.$data.item.color.code = 1;
        self.$data.item.style.code = 1;
        self.$data.item.unit.code = 2;

        if(self.$data.item.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        const requestOptions = {
          method:  (self.$data.item.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/item/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );
            self.item = JSON.parse('{"id":"","code":0,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":1,"name":"","p_name":""},"brand":{"id":"","code":1,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":3,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0}');
            self.closeWindow();
            self.$parent.$parent.loadData();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
